<template>
    <section>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <tabla-general
                :data="dataTable"
                :columnas="dataColumns"
                alto="calc(100vh - 275px)"
                class-header="text-general f-16"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-600">
                            Retiros e Ingresos de todas la cajas
                        </div>
                        <div class="col d-flex">
                            <div class="bg-light-f5 ml-auto border cr-pointer d-middle px-2 br-20 text-general position-relative" @click="filtrar">
                                <div v-if="filtro" class="rounded-circle bg-general position-absolute" style="width:10px;height:10px;right:-2px;top:-5px;" />
                                <i class="icon-filter-outline f-16" /> Filtrar
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        label="Fecha"
                        width="180"
                        sort-by="created_at"
                        sortable
                        >
                            <template slot-scope="{ row }">
                                <p>{{ formatearFecha(row.created_at, 'D MMM Y - HH:mmA') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Valor"
                        width="150"
                        sort-by="valor"
                        sortable
                        >
                            <template slot-scope="{ row }">
                                <p :class="row.tipo == 0 ? 'text-general-red' : 'text-green'">
                                    {{ separadorNumero(row.valor) }}
                                </p>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-filtro ref="modalFiltro" @filtrar="listarMovimientos" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Movimientos from "~/services/flujoDinero/movimientos";
export default {
    components: {
        modalFiltro : () => import('./partials/modalFiltro')
    },
    data(){
        return {
            dataTable: [],
            dataColumns: [
                { valor: 'responsable', titulo: 'Responsable', class: 'text-general text-center' },
                { valor: 'caja', titulo: 'Caja', class: 'text-general text-center' },
                { valor: 'observaciones', titulo: 'Comentario', ancho: 400, class: 'text-general text-left' },
            ],
            filtro: 0
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda == null){
                    setTimeout(async() => {
                        await this.listarMovimientos()
                    }, 1000);
                }else{
                    await this.listarMovimientos()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        filtrar(){
            this.$refs.modalFiltro.toggle(this.filtro);
        },
        async listarMovimientos(params = {}){
            try {
                this.filtro = params.filtro
                params.id_moneda = this.id_moneda
                const { data } = await Movimientos.listarMovimientos(params)
                this.dataTable = data.data.cajasRetiros
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.text-green{
    color: #28D07B !important;
}
</style>
