import axios from "axios"

const prefix = "flujo-dinero/movimientos";

const flujoDinero = {
    listarMovimientos: params => axios.get(`${prefix}/listar`, { params }),
    filtroMovimientos: () => axios.get(`${prefix}/filtro`)
};

export default flujoDinero
